<template>
  <div v-if="Object.values(isDataReady).every(v => v === true)" class="h-full w-full">
    <div v-if="!selectedFeed.id" class="flex h-full w-full flex-col">
      <div class="m-auto">
        <div class="flex h-5 w-full items-center space-x-2">
          <ExclamationCircleIcon class="h-4 w-4 text-gray-400" />
          <span class="block text-xs">Select a News Feed to Visualize</span>
        </div>
      </div>
    </div>
    <span v-else>
      <div class="flex h-full flex-col space-y-4">
        <div class="flex-1 overflow-y-scroll rounded border border-gray-800">
          <NewsFeed
            class="h-full"
            :feed="selectedFeed"
            :folder="selectedFolder"
            :search="debouncedSearch"
            :key="`${selectedId}_${renderKey}`"
            :leave-off-time="leaveOffTime"
            @news-list="newsItems = $event"
          />
        </div>
      </div>
    </span>
  </div>
  <div v-else class="flex flex-col pr-8">
    <BaseLoader class="m-auto h-5 w-5" />
  </div>
</template>
<script setup>
import { ref, onMounted, inject, computed, watch } from 'vue';
import { useStore } from 'vuex';
import { ExclamationCircleIcon } from '@heroicons/vue/24/outline';
import NewsFeed from '@/components/news_feeds/NewsFeed.vue';
import useEmitter from '@/composeables/emitter';

const emitter = useEmitter();
const $http = inject('http');
const store = useStore();

const isDataReady = ref({ news: true });

const selectedId = ref(null);
const selectedFeed = ref({
  id: undefined
});

const keywords = ref([]);
const renderKey = ref(Math.random().toString());
const selectedFolder = ref(null);
const debouncedSearch = ref('');
const leaveOffTime = ref('');
const newsItems = ref([]);

const alertConfig = ref({});

const feeds = ref([]);

async function fetchNewsFeeds() {
  isDataReady.value.news = false;
  const response = await $http.get('/news_feeds');
  feeds.value = response.data.news_feeds.filter(x => !store.getters.defaultFeeds.includes(x.name));
  isDataReady.value.news = true;
}

onMounted(() => {
  if (props.alert) {
    selectedId.value = props.alert.config.news_feed_id;
    selectedFeed.value.id = props.alert.config.news_feed_id;
    fetchNewsFeeds();
  }
  emitter.$on('config:alert', data => {
    alertConfig.value = data.config;
    selectedId.value = data.config?.news_feed_id;
    selectedFeed.value.id = selectedId.value;
    fetchNewsFeeds();
  });
});

const apiFeedState = computed(() => {
  function getKeywordsList() {
    return keywords.value.map(e => {
      return `Form: ${e} `;
    });
  }
  return {
    keywords: getKeywordsList()
  };
});

const props = defineProps({
  alert: {
    type: Object,
    default: () => null
  }
});
</script>
